import NurseMap from 'components/NurseMap'
import { useAPI } from 'contexts/APIProvider'
import NurseMapPoint from 'logic/NurseMapPoint'
import AdminPracticeDetailContext from 'pages/AdminPracticeDetail/context'
import React from 'react'

const AdminPracticeNurseMap: React.FC = () => {
    const { api } = useAPI()
    const { practice } = React.useContext(AdminPracticeDetailContext)
    const [mapPoints, setMapPoints] = React.useState<NurseMapPoint[]>([])

    const fetchMapPoints = React.useCallback(async () => {
        if (!practice) return
        setMapPoints(await api.listNurseMapPoints({ 
            practice_id: practice.practice_id,
            role: 'dental nurse'  // TODO: add a toggle to switch between dental nurse and hygienist
        }))
    }, [practice, api])

    React.useEffect(() => {
        fetchMapPoints()
    }, [fetchMapPoints])

    return (
        <NurseMap practice={practice} points={mapPoints} sx={{ width: '90%', height: '80%' }} />
    )
}

export default AdminPracticeNurseMap

