import React from 'react'
import { Typography } from '@mui/material'
import { NursePaygradeStats } from 'types/interfaces'
import { round } from 'lodash'
import { HYGIENIST_HOURLY_RATE } from 'types/constants'
import Practice from 'logic/Practice'

type JobFormFooterProps = {
  practice: Practice
  paygradeStats: NursePaygradeStats
}

const JobFormFooter: React.FC<JobFormFooterProps> = ({ practice, paygradeStats }) => {
    return (
      <Typography variant='body2'>
        <ul>
          <li>
            Our Fees
            <ul>
              <li>Dental nurses: {practice.locumloop_fees_label('dental nurse')} per day.</li>
              <li>Hygienists: {practice.locumloop_fees_label('hygienist')} per day.</li>
            </ul>
          </li>
          <li>Our dental nurses earn between £{round(paygradeStats.min_rate, 2)} and £{round(paygradeStats.max_rate, 2)} per hour based on their experience with locumloop.</li>
          <li>Average hourly rate of our dental nurses: £{round(paygradeStats.median_rate, 2)}/hour</li>
          <li>Our hygienists are £{HYGIENIST_HOURLY_RATE}/hour</li>
          <li>Jobs are advertised to nurses immediately and often taken within 30 minutes.</li>
          <li>Nurses can accept jobs on a first-come, first-serve basis.</li>
        </ul>
      </Typography>
    )
}

export default JobFormFooter