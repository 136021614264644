import React from 'react'
import { useAPI } from 'contexts/APIProvider'
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import CancelOutlined from '@mui/icons-material/CancelOutlined'
import Header from 'components/Header'
import update from 'immutability-helper';
import { EditJobForm, EstimateCost } from 'types/interfaces'
import EditJobError from 'errors/EditJobError'
import { practiceViewJobURL } from 'routes/urls'
import { isEmpty } from 'lodash';
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider';
import { useJob } from 'contexts/JobProvider';
import JobForm from 'components/JobForm';
import { usePractice } from 'contexts/PracticeProvider';

const PracticeEditJob: React.FC = () => {
  const { practice, paygradeStats } = usePractice()
  const { api } = useAPI()
  const { showAlert } = useSnackBarAlert()
  const navigate = useNavigate()
  const { job, reloadJob } = useJob()
  const [form, setForm] = React.useState<EditJobForm>({} as EditJobForm);
  const [error, setError] = React.useState<EditJobError>();
  const [loading, setLoading] = React.useState<boolean>(false)
  const [estimatedCost, setEstimatedCost] = React.useState<EstimateCost | null>()

  function updateForm(name: string, value: any) {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }

  const fetchEstimatedCost = React.useCallback(async () => {
    if (job && form.start_time && form.end_time && form.lunch_break !== undefined && form.headcount) {
      setEstimatedCost(await api.getEstimatedCost(
        job.practice_id,
        {
          date: job.start_at.toISODate(),
          start_time: form.start_time,
          end_time: form.end_time,
          lunch_break: form.lunch_break,
          headcount: form.headcount,
          role: form.hygienist_required ? 'hygienist' : 'dental nurse'
        }
      ))
    }
  }, [job, form.start_time, form.end_time, form.lunch_break, form.headcount, form.hygienist_required, api])

  async function save() {
    if (job === undefined) {
      return
    }
    try {
      setLoading(true)
      await api.editJob(job.job_id, form)
      await reloadJob()
      showAlert('success', 'Job Updated')
      navigate(practiceViewJobURL(job.practice_id, job.job_id))
    } catch (e) {
      if (e instanceof EditJobError) {
        setError(e)
      } else {
        console.error('unhandled exception', e)
      }
    } finally {
      setLoading(false)
    }
  }

  async function prepopulateForm() {
    if (job === undefined) return
    if (!isEmpty(form)) return
    const tz = job.practice_tz
    setForm((form) => update(form, {
      start_time: { $set: job.start_at.setZone(tz).toFormat('HH:mm') },
      end_time: { $set: job.end_at.setZone(tz).toFormat('HH:mm') },
      lunch_break: { $set: job.lunch_break.as('minute') },
      headcount: { $set: job.headcount },
      description: { $set: job.description },
      hygienist_required: { $set: job.hygienist_required }
    }))
  }

  function goBack() {
    if (job !== undefined) navigate(practiceViewJobURL(job.practice_id, job.job_id))
  }


  React.useEffect(() => {
    prepopulateForm()
  }, [job])

  React.useEffect(() => {
    fetchEstimatedCost()
  }, [fetchEstimatedCost])

  if (job === undefined || isEmpty(form)) {
    return null
  }

  return (
    <Paper sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Header variant='h2' text={job.date_label(job.practice_tz)}>
        <IconButton onClick={goBack}>
          <CancelOutlined />
        </IconButton>
      </Header>
      <JobForm
        form={form}
        error={error}
        updateForm={updateForm}
        mode='edit'
        paygradeStats={paygradeStats}
        estimatedCost={estimatedCost}
      />
      <Button variant="contained" color="primary" onClick={save} disabled={loading}>
        {loading ? 'Please Wait ...' : 'Confirm Changes'}
      </Button>
      <Button
        variant="outlined" color="primary" disabled={loading}
        onClick={goBack}>
        Cancel
      </Button>
    </Paper>
  )
}

export default PracticeEditJob