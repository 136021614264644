import { Box } from '@mui/material'
import DescriptionList from 'components/DescriptionList'
import AdminPracticeDetailContext from 'pages/AdminPracticeDetail/context'
import React from 'react'

const AdminPracticeProfile: React.FC = () => {
  const { practice } = React.useContext(AdminPracticeDetailContext)
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <DescriptionList labelWidth='20%' valueWidth='80%' rowGap={1} data={[{
        label: 'ID',
        value: practice.practice_id.toString()
      }, {
        label: 'Practice Name',
        value: practice.practice_name
      }, {
        label: 'Address',
        value: practice.address,
        sx: { whiteSpace: 'pre-line' }
      }, {
        label: 'Contacts',
        value: practice.contacts_label,
        sx: { whiteSpace: 'pre-line' }
      }, {
        label: 'First Member',
        value: practice.first_member_label,
      }, {
        label: 'Sign Up At',
        value: practice.signup_at_label,
      }, {
        label: 'Locumloop Fees (Dental Nurse)',
        value: practice.locumloop_fees_label('dental nurse')
      }, {
        label: 'Locumloop Fees (Hygienist)',
        value: practice.locumloop_fees_label('hygienist')
      }, {
        label: 'Practice Group',
        value: practice.practice_group_name || 'N/A'
      }, {
        label: 'Affiliate',
        value: practice.affiliate_code || 'N/A',
      }, {
        label: 'Enabled',
        value: practice.enabled ? 'Yes': 'No',
      }, {
        label: 'Ready to process payments',
        value: practice.is_stripe_ready ? 'Yes': practice.not_stripe_ready_reason,
      }, {
        label: 'Stripe Customer ID',
        value: practice.stripe_customer_id || 'N/A'
      }, {
        label: 'Late Cancellation Policy',
        value: practice.late_cancellation_policy,
      }]} />
    </Box>
  )
}

export default AdminPracticeProfile