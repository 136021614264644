import { Alert, Box, Button } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'
import Header from 'components/Header'
import update from 'immutability-helper';
import { useAPI } from 'contexts/APIProvider'
import AddJobError from 'errors/AddJobError'
import React from 'react'
import { useNavigate } from 'react-router'
import { practiceSignupURL } from 'routes/urls'
import { AddJobForm } from 'types/interfaces'
import PracticeSignupContext from '../context'
import { EstimateCost } from 'types/interfaces';
import JobForm from 'components/JobForm';
import NurseMap from 'components/NurseMap';
import NurseMapPoint from 'logic/NurseMapPoint';
import JobFormFooter from 'components/JobFormFooter';

const initialValues = {
  start_time: '09:00',
  end_time: '17:00',
  lunch_break: 60,
  headcount: 1
}

export const MIN_NURSE_COUNT_FOR_SHOW = 5

const PracticeSignupJobAdd: React.FC = () => {
  const { practice, reloadJobs, emitGTMEvent, paygradeStats } = React.useContext(PracticeSignupContext)
  const { api } = useAPI()
  const navigate = useNavigate()
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const [form, setForm] = React.useState<AddJobForm>(initialValues)
  const [error, setError] = React.useState<AddJobError>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [estimatedCost, setEstimatedCost] = React.useState<EstimateCost | null>()
  const [mapPoints, setMapPoints] = React.useState<NurseMapPoint[]>([])

  function updateForm(name: string, value: any) {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
  }

  const fetchEstimatedCost = React.useCallback(async () => {
    if (practice && form.date && form.start_time && form.end_time && form.lunch_break !== undefined && form.headcount) {
      setEstimatedCost(await api.getEstimatedCost(
        practice?.practice_id,
        {
          date: form.date,
          start_time: form.start_time,
          end_time: form.end_time,
          lunch_break: form.lunch_break,
          headcount: form.headcount,
          role: form.hygienist_required ? 'hygienist' : 'dental nurse'
        }
      ))
    }
  }, [practice, form.date, form.start_time, form.end_time, form.lunch_break, form.headcount, form.hygienist_required, api])

  const fetchMapPoints = React.useCallback(async () => {
    if (!practice) return
    setMapPoints(await api.listNurseMapPoints({ 
      practice_id: practice.practice_id,
      role: form.hygienist_required ? 'hygienist' : 'dental nurse'
    }))
  }, [practice, form.hygienist_required, api])

  const save = React.useCallback(async () => {
    try {
      setLoading(true)
      await api.addJob(form)
      await reloadJobs()
      emitGTMEvent('practice-signup-add-job')
      navigate(practiceSignupURL())
    } catch (e) {
      if (e instanceof AddJobError) {
        setError(e)
      } else {
        console.error('unhandled exception', e)
      }
    } finally {
      setLoading(false)
    }
  }, [api, emitGTMEvent, form, navigate, reloadJobs])

  const isShowNurseCount = React.useMemo(() => mapPoints.length >= MIN_NURSE_COUNT_FOR_SHOW, [mapPoints])
  const isShowNurseMap = React.useMemo(() => isDesktop && isShowNurseCount, [isDesktop, isShowNurseCount])

  React.useEffect(() => {
    if (practice) updateForm('practice_id', practice.practice_id)
  }, [practice])
  
  React.useEffect(() => {
    fetchEstimatedCost()
  }, [fetchEstimatedCost])

  React.useEffect(() => {
    fetchMapPoints()
  }, [fetchMapPoints])

  const numNurses = mapPoints.length

  return (
    <Box sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Header variant='h2' text='Add Job Post'></Header>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 1.5 }}>
          {isShowNurseCount  && (
            <Alert severity='info' variant='filled'>
              We have {numNurses} {form.hygienist_required ? 'hygienist' : 'nurse'} looking for jobs around your practice
            </Alert>
          )}
          <JobForm
            form={form}
            error={error}
            updateForm={updateForm}
            mode='add'
            paygradeStats={paygradeStats}
            estimatedCost={estimatedCost}
            canRequestSpecificNurse={false}
          />
          <Button variant="contained" color="primary" onClick={save} disabled={loading}>
            {loading ? 'Please Wait ...' : 'Post Job'}
          </Button>
          { practice && paygradeStats ? <JobFormFooter practice={practice} paygradeStats={paygradeStats} /> : null}
        </Box>
        {isShowNurseMap ? (
          <NurseMap points={mapPoints} practice={practice} sx={{ flex: 1 }} />
        ) : null}
      </Box>
      <Button variant='outlined' onClick={() => navigate(-1)}>
        Back
      </Button>
    </Box>
  )
}

export default PracticeSignupJobAdd